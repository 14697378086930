* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
}

.container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  padding: 10px;
}

.card {
  background-color: white;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}

/* .header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e2d2f; 
  padding: 20px;
} */
 /* Styles for the header */
.header {
  display: flex;
  flex-direction: column; /* Stack the logo and text vertically */
  align-items: center;    /* Center align items horizontally */
  justify-content: center; /* Center align items vertically */
  padding: 20px;          /* Add some padding */
  text-align: center;     /* Center the text */
}

/* Styles for the logo */
.logo {
  width: 100px;   /* Adjust the size of the logo as needed */
  height: auto;
  margin-bottom: 10px; /* Space between logo and heading */
}

h2 {
  font-size: 1.5rem; /* Adjust the size of the heading */
  color: #000;  /* Set the color for light mode */
}

body.dark h2 {
  color: #fff;  /* Set the color for dark mode */
}


.logo {
  width: 56px;
  height: 50px;
  margin-right: 10px;
}

h2 {
  color: #b9ffc0; /* Lighter shade from the logo */
  font-size: 18px;
}

.blog-section {
  background-color: #f4f9f9; /* Light background color */
  color: #4CAF50; /* Green for text to match logo */
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.blog-section h2 {
  color: #2b5f5a; /* Darker green tone */
}

.blog-section h3 {
  color: #1e4035; /* Slightly darker green tone */
}

.read-more, .share {
  background-color: #4CAF50; /* Matching green button color */
  color: white;
  border: none;
  padding: 10px;
  width: 45%;
  border-radius: 5px;
  margin-top: 10px;
  margin-right: 10px;
}

.read-more:hover, .share:hover {
  background-color: #45a049;
}

.card {
  background-color: #ffffff; /* Clean white background */
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

h2, p {
  color: #1e4035;  /* Darker green from the logo */
}

.bar-chart {
  margin-top: 20px;
}

.card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

